import React from 'react';

const ActivityPage = () => {
  return (
    <div id="activity-page">
      <section id="schedule" className="section">
        <div className="container">
          <h1 className="title">Activities</h1>
          <h2 className="subtitle">Activities for the <strong>2025</strong> season. More details coming soon, minor details might change without notice.</h2>
          <table className="table is-striped is-narrow is-hoverable is-fullwidth">
            <thead>
            <tr>
              <th>Date</th>
              <th width="20%">Activity</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            {/*April*/}
            {/*            <tr>
              <td colSpan="3" className="has-background-success"><strong className="has-text-white">April</strong></td>
            </tr>
            <tr>
              <td>28<sup>th</sup></td>
              <td colSpan="2">Red Rock Ponds RV Resort Opens For The Season</td>
            </tr>*/}
            {/*May*/}
            <tr>
              <td colSpan="3" className="has-background-success"><strong className="has-text-white">May</strong></td>
            </tr>
            <tr className="separator">
              <td colSpan="3"><strong>Opening Weekend</strong></td>
            </tr>
            <tr className="separator">
              <td>2<sup>nd</sup></td>
              <td colSpan="2">Red Rock Ponds RV Resort Opens For The Season</td>
            </tr>
            <tr>
              <td>3<sup>rd</sup></td>
              <td className="has-text-right">8:00PM</td>
              <td>Bonfire - Inaugural campfire of the summer, join us up front.</td>
            </tr>
            <tr className="separator">
              <td colSpan="3"><strong>Mother&apos;s Day Weekend</strong></td>
            </tr>
            <tr className="separator">
              <td>9<sup>th</sup></td>
              <td className="has-text-right">5:00PM - 7:00PM</td>
              <td>Food Truck - <a href="https://www.roccitysammich.com/menu">Roc CIty Sammich</a></td>
            </tr>
            <tr>
              <td rowSpan="4">10<sup>th</sup></td>
              <td className="has-text-right" rowSpan="2">10:30AM - 11:30AM</td>
              <td>Yoga with Mandy! - All skill levels welcome! Kids who can follow directions.</td>
            </tr>
            <tr>
              <td>Crafting - Kid&apos;s crafting</td>
            </tr>
            <tr>
              <td className="has-text-right">3:00PM - 4:00PM</td>
              <td>Mom&apos;s Party - Mom&apos;s themed party</td>
            </tr>
            <tr>
              <td className="has-text-right">7:00PM - 8:00PM</td>
              <td>BINGO - All money returned prizes, win cool Red Rock merchandise!</td>
            </tr>
            <tr>
              <td>11<sup>th</sup></td>
              <td className="has-text-right">9:00AM - 10:00AM</td>
              <td>All you can eat breakfast w/ blueberry pancakes with all the toppings! Coffee and
                juice available. $4 per person. <br/>Mom eats free! Kids under 2 eat free.
              </td>
            </tr>
            <tr className="separator">
              <td rowSpan="2">17<sup>th</sup></td>
              <td className="has-text-right">10:30AM - 11:30AM</td>
              <td>Yoga with Mandy! - All skill levels welcome! Kids who can follow directions.</td>
            </tr>
            <tr className="separator">
              <td className="has-text-right">6:00PM - 10:00PM</td>
              <td>Poker night - Beginners $5 Stake (Texas hold &apos;em) - 4 Hour Format - All Money Returned</td>
            </tr>
            <tr className="separator">
              <td colSpan="3"><strong>Memorial Day Weekend</strong></td>
            </tr>
            <tr className="separator">
              <td rowSpan="3">24<sup>th</sup></td>
              <td className="has-text-right">10:30AM - 11:30AM</td>
              <td>Yoga with Mandy! - All skill levels welcome! Kids who can follow directions.</td>
            </tr>
            <tr className="separator">
              <td className="has-text-right">12:00PM - 1:00PM</td>
              <td>Beachside Cookout - Free burgers, hot dogs. Join us for a picnic at the beach.</td>
            </tr>
            <tr>
              <td className="has-text-right">7:00PM - 9:00PM</td>
              <td>Chris Moore - Live Music - Country Rock.</td>
            </tr>

            <tr>
              <td>25<sup>th</sup></td>
              <td className="has-text-right">7:00PM - 8:00PM</td>
              <td>BINGO - All money returned prizes, win cool Red Rock merchandise!</td>
            </tr>
            <tr className="separator">
              <td>29<sup>th</sup></td>
              <td className="has-text-right">3:00PM - 4:00PM</td>
              <td>Book Club with Michelle - &quot;The Kitchen House&quot; by Kathleen Grisson - Meeting at beach pergolas or in
                clubhouse depending on weather
              </td>
            </tr>
            <tr className="separator">
              <td rowSpan="2">31<sup>st</sup></td>
              <td className="has-text-right">10:30AM - 11:30AM</td>
              <td>Yoga with Mandy! - All skill levels welcome! Kids who can follow directions.</td>
            </tr>
            <tr>
              <td className="has-text-right">7:00PM</td>
              <td>Trivia Night</td>
            </tr>
            {/*June*/}
            <tr>
              <td colSpan="3" className="has-background-success"><strong className="has-text-white">June</strong></td>
            </tr>
            <tr className="separator">
              <td>6<sup>th</sup></td>
              <td className="has-text-right">5:00PM - 7:00PM</td>
              <td>Food Truck - <a href="https://www.facebook.com/GreenliefsOnTheGo/">Greenlief&apos;s on the Go</a></td>
            </tr>
            <tr>
              <td rowSpan="3">7<sup>th</sup></td>
              <td className="has-text-right">10:30AM - 11:30AM</td>
              <td>Yoga with Mandy! - All skill levels welcome! Kids who can follow directions.</td>
            </tr>
            <tr>
              <td className="has-text-right">2:00PM - 4:00PM</td>
              <td>Fishing Derby</td>
            </tr>
            <tr>
              <td className="has-text-right">6:00PM - 7:00PM</td>
              <td>Happy Hour</td>
            </tr>
            <tr className="separator">
              <td colSpan="3"><strong>Father&apos;s Day Weekend</strong></td>
            </tr>
            <tr className="separator">
              <td rowSpan="4">14<sup>th</sup></td>
              <td className="has-text-right">10:30AM - 11:30AM</td>
              <td>Yoga with Mandy! - All skill levels welcome! Kids who can follow directions.
              </td>
            </tr>
            <tr>
              <td className="has-text-right">10:00AM - 12:00PM</td>
              <td>Crafting - TBA
              </td>
            </tr>
            <tr>
              <td className="has-text-right">3:00PM - 4:00PM</td>
              <td>Dad&apos;s Party - Mini Oktoberfest with hearty sausages, burgers, and ice-cold beer—good food, good
                drinks, and a great time! 🍻🔥
              </td>
            </tr>
            <tr>
              <td className="has-text-right">7:00PM - 8:00PM</td>
              <td>BINGO - All money returned prizes, win cool Red Rock merchandise!</td>
            </tr>
            <tr>
              <td>15<sup>th</sup></td>
              <td className="has-text-right">9:00AM - 10:00AM</td>
              <td>All you can eat breakfast w/ blueberry pancakes with all the toppings! Coffee and
                juice available. $4 per person. Fathers eat free!
              </td>
            </tr>
            <tr>
              <td>20<sup>th</sup></td>
              <td className="has-text-right">5:00PM - 7:00PM</td>
              <td>Food Truck - <a href="https://www.facebook.com/Dubbyswoodfired/">Dubbys Wood Fired Pizza</a></td>
            </tr>
            <tr className="separator">
              <td rowSpan="2">21<sup>st</sup></td>
              <td className="has-text-right">10:30AM - 11:30AM</td>
              <td>Yoga with Mandy! - All skill levels welcome! Kids who can follow directions.</td>
            </tr>
            <tr>
              <td className="has-text-right">7:00PM</td>
              <td>Campfire Crawl - Like a pub crawl, visit designated bonfires around the campground and try the unique
                drinks each hosts has to offer.
              </td>
            </tr>
            <tr className="separator">
              <td>26<sup>th</sup></td>
              <td className="has-text-right">3:00PM - 4:00PM</td>
              <td>Book Club with Michelle - &quot;The Silent Patient&quot; by Alex Michaelides - Meeting at beach pergolas or in
                clubhouse depending on weather
              </td>
            </tr>
            <tr className="separator">
              <td rowSpan="3">28<sup>th</sup></td>
              <td className="has-text-right">10:30AM - 11:30AM</td>
              <td>Yoga with Mandy! - All skill levels welcome! Kids who can follow directions.</td>
            </tr>
            <tr>
              <td className="has-text-right">4:00PM - 6:00PM</td>
              <td>Bake-off Challenge - Fire up those ovens and bring your best baked creation to the ultimate campground bake-off! Whether it’s a classic pie, a decadent cake, or a creative twist on a favorite treat, all bakers are welcome to compete. Show off your skills, share some sweet treats, and enjoy a little friendly competition! 🍰🥧🔥</td>
            </tr>
            <tr>
              <td className="has-text-right">7:00PM - 10:00PM</td>
              <td><a href="https://www.facebook.com/p/McHenry-and-Baz-100063616298954/">McHenry And Baz</a> - Live music - Great music from the 60’s, 70’s 80’s and 90’s.</td>
            </tr>
            {/*July*/}
            <tr>
              <td colSpan="3" className="has-background-success"><strong className="has-text-white">July</strong></td>
            </tr>
            <tr>
              <td colSpan="3"><strong>4th of July Weekend</strong></td>
            </tr>
            <tr className="separator">
              <td rowSpan="2">4<sup>th</sup></td>
              <td className="has-text-right">10:00AM - 6:00PM</td>
              <td>Giant water slide Friday and Saturday.</td>
            </tr>
            <tr>
              <td className="has-text-right">7:00PM - 8:00PM</td>
              <td>BINGO - All money returned prizes, win cool Red Rock merchandise!</td>
            </tr>
            <tr>
              <td rowSpan="4">5<sup>th</sup></td>
              <td className="has-text-right">10:30AM - 11:30AM</td>
              <td>Yoga with Mandy! - All skill levels welcome! Kids who can follow directions.</td>
            </tr>
            <tr>
              <td className="has-text-right">2:00PM - 3:00PM</td>
              <td>4th of July Parade - Join us for a star-spangled salute at the 4th of July Parade! Honoring our Brave
                First Responders 🚑 🚒 🚓 This year, we&apos;re saluting our first responders in a dazzling display of
                community spirit. Decorate your golf carts in patriotic flair and roll down the streets with pride.
              </td>
            </tr>
            <tr>
              <td className="has-text-right">After Parade</td>
              <td>Ice Cream Social - Delicious Perry&apos;s ice cream. Plenty of flavors to choose from.</td>
            </tr>
            <tr>
              <td className="has-text-right">7:00PM - 9:30PM</td>
              <td><a href="https://www.facebook.com/profile.php?id=61567310934270">Black Jack</a> - Live music - Classic rock cover band.</td>
            </tr>
            <tr className="separator">
              <td colSpan="3"><strong>Christmas in July Weekend</strong></td>
            </tr>
            <tr className="separator">
              <td>11<sup>th</sup></td>
              <td className="has-text-right">5:00PM - 7:00PM</td>
              <td>Food Truck - <a href="https://www.facebook.com/RedOsierFoodService/">Red Osier Food Truck</a></td>
            </tr>
            <tr className="separator">
              <td rowSpan="2">12<sup>th</sup></td>
              <td className="has-text-right">10:30AM - 11:30AM</td>
              <td>Yoga with Mandy! - All skill levels welcome! Kids who can follow directions.</td>
            </tr>
            <tr>
              <td className="has-text-right">2:00PM - 4:00PM</td>
              <td>Christmas in July - TBA</td>
            </tr>
            <tr className="separator">
              <td rowSpan="4">19<sup>th</sup></td>
              <td className="has-text-right">10:30AM - 11:30AM</td>
              <td>Yoga with Mandy! - All skill levels welcome! Kids who can follow directions.</td>
            </tr>
            <tr>
              <td rowSpan="2" className="has-text-right">4:00PM - 6:00PM</td>
              <td>Beach Party - Live DJ! Giant floats! Free toys and water blasters! Slushies for kids and drinks for
                adults. Enjoy the hot sun, warm sand and refreshing water.
              </td>
            </tr>
            <tr>
              <td>DJ by <a href="https://www.facebook.com/profile.php?id=61564314246957">Fireside Beats</a></td>
            </tr>
            <tr>
              <td className="has-text-right">6:00PM - 7:00PM</td>
              <td>Chicken BBQ Dinner
                - Prepared by <a href="/">TBA</a>. Fill up on
                delicious, mouth watering BBQ chicken! Three sides included. Ticket purchased in advance, remainder
                tickets can be purchased in person.
              </td>
            </tr>
            <tr className="separator">
              <td rowSpan="2">26<sup>th</sup></td>
              <td className="has-text-right">10:30AM - 11:30AM</td>
              <td>Yoga with Mandy! - All skill levels welcome! Kids who can follow directions.</td>
            </tr>
            <tr>
              <td className="has-text-right">12:00PM - 3:00PM</td>
              <td>FHM/Holley Fire Department Fund Raiser Carnival</td>
            </tr>
            <tr className="separator">
              <td>31<sup>st</sup></td>
              <td className="has-text-right">3:00PM - 4:00PM</td>
              <td>Book Club with Michelle - &quot;The Hate You Gave&quot; by Angie Thomas - Meeting at beach pergolas or in
                clubhouse depending on weather
              </td>
            </tr>
            {/*August*/}
            <tr>
              <td colSpan="3" className="has-background-success"><strong className="has-text-white">August</strong></td>
            </tr>
            <tr className="separator">
              <td rowSpan="3">2<sup>rd</sup></td>
              <td className="has-text-right">10:30AM - 11:30AM</td>
              <td>Yoga with Mandy! - All skill levels welcome! Kids who can follow directions.</td>
            </tr>
            <tr className="separator">
              <td className="has-text-right">2:00PM - 5:00PM</td>
              <td>Pickle Ball Tournament</td>
            </tr>
            <tr>
              <td className="has-text-right">6:00PM - 7:00PM</td>
              <td>Happy Hour - Catch up, meet new people! Bring a appetizer, refreshment provided.</td>
            </tr>
            <tr className="separator">
              <td rowSpan="3">9<sup>th</sup></td>
              <td className="has-text-right">10:30AM - 11:30AM</td>
              <td>Yoga with Mandy! - All skill levels welcome! Kids who can follow directions.</td>
            </tr>
            <tr className="separator">
              <td className="has-text-right">10:00AM - 12:00PM</td>
              <td>Indigo Splash: Design, Dye, and make dye-tastic shirts</td>
            </tr>
            <tr>
              <td className="has-text-right">7:00PM - 8:00PM</td>
              <td>BINGO - All money returned prizes, win cool Red Rock merchandise!</td>
            </tr>

            <tr className="separator">
              <td rowSpan="6">16<sup>th</sup></td>
              <td className="has-text-right">10:30AM - 11:30AM</td>
              <td>Yoga with Mandy! - All skill levels welcome! Kids who can follow directions.</td>
            </tr>
            <tr>
              <td className="has-text-right">4:00PM - 6:00PM</td>
              <td><a href="https://northridgedistillery.com/">North Ridge Distillery</a> From farm to flask; Huckleberry Punch, Forbidden Apple 2.0, The Bee&apos;s Knees and much more.
              </td>
            </tr>
            <tr>
              <td className="has-text-right">6:00PM - 7:00PM</td>
              <td>Pig Roast - Tender, juicy and savory prime BBQ pulled pork smoked all day! Served with 3 sides; Baked
                beans, tri-color pasta and mac salad.
              </td>
            </tr>
            <tr>
              <td rowSpan="3" className="has-text-right">7:00PM - 9:30PM</td>
              <td><a href="https://www.facebook.com/p/The-Who-Dats-100063582307146/">Who Dats</a> - Live Music - Mix of southern rock, funk, dance, and soft rock tunes that cater to all musical tastes!</td>
            </tr>
            <tr>
              <td>Tiki Island Beach Bonfire - Join us for a magical night under the stars. Cozy up in our annual Tiki
                island beach bonfire in an intimate setting. Dance to the timeless classics and indulge a yummy island
                drink, or two.
              </td>
            </tr>
            <tr>
              <td>Bar Tended by <a href="">TBA</a></td>
            </tr>
            <tr className="separator">
              <td rowSpan="3">23<sup>rd</sup></td>
              <td className="has-text-right">10:30AM - 11:30AM</td>
              <td>Yoga with Mandy! - All skill levels welcome! Kids who can follow directions.</td>
            </tr>
            <tr>
              <td className="has-text-right">1:00PM - 3:00PM</td>
              <td>Paint with Teresa</td>
            </tr>
            <tr>
              <td className="has-text-right">6:00PM - 9:00PM</td>
              <td>Poker night - Pro-Am $20 Stake (Texas hold &apos;em) - 3 Hour Format - All Money Returned</td>
            </tr>
            <tr className="separator">
              <td>28<sup>th</sup></td>
              <td className="has-text-right">3:00PM - 4:00PM</td>
              <td>Book Club with Michelle - &quot;A Thousand Splendid Suns&quot; by Khaled Hosseini - Meeting at beach pergolas or
                in clubhouse depending on weather
              </td>
            </tr>
            <tr className="separator">
              <td colSpan="3"><strong>Labor Day Weekend / Kids Weekend</strong></td>
            </tr>
            <tr className="separator">
              <td>29<sup>th</sup></td>
              <td className="has-text-right">5:00PM - 7:00PM</td>
              <td>Food Truck - <a href="https://www.facebook.com/curbsidequesadilla/">Curbside Quesadillas</a></td>
            </tr>
            <tr>
              <td>30<sup>th</sup></td>
              <td className="has-text-right">10:30AM - 11:30AM</td>
              <td>Yoga with Mandy! - All skill levels welcome! Kids who can follow directions.</td>
            </tr>
            <tr>
              <td rowSpan="3">30<sup>st</sup></td>
              <td className="has-text-right">2:00PM - 3:00PM</td>
              <td>Red Rock 500 - Matchbox Cars Track Racing and derby. Bring your own car.</td>
            </tr>
            <tr>
              <td className="has-text-right">After Race</td>
              <td>Ice Cream Social - Delicious Perry&apos;s ice cream. Plenty of flavors to choose from.</td>
            </tr>
            <tr>
              <td className="has-text-right">7:00PM - 8:00PM</td>
              <td>BINGO - All money returned prizes, win cool Red Rock merchandise!</td>
            </tr>
            <tr>
              <td>31<sup>st</sup></td>
              <td className="has-text-right">2:00PM - 4:00PM</td>
              <td>Kid&apos;s Activity</td>
            </tr>
            {/*September*/}
            <tr>
              <td colSpan="3" className="has-background-success"><strong className="has-text-white">September</strong>
              </td>
            </tr>
            <tr className="separator">
              <td rowSpan="2">6<sup>th</sup></td>
              <td className="has-text-right">10:30AM - 11:30AM</td>
              <td>Yoga with Mandy! - All skill levels welcome! Kids who can follow directions.</td>
            </tr>
            <tr>
              <td className="has-text-right">7:00PM</td>
              <td>Trivia Night</td>
            </tr>

            <tr className="separator">
              <td rowSpan="3">13<sup>th</sup></td>
              <td className="has-text-right">10:30AM - 11:30AM</td>
              <td>Yoga with Mandy! - All skill levels welcome! Kids who can follow directions.</td>
            </tr>
            <tr>
              <td className="has-text-right">2:00PM - 4:00PM</td>
              <td>Cornhole Tournament - Win a custom cornhole board and bragging rights!</td>
            </tr>
            <tr>
              <td className="has-text-right">7:00PM - 8:00PM</td>
              <td>BINGO - All money returned prizes, win cool Red Rock merchandise!</td>
            </tr>
            <tr className="separator">
              <td rowSpan="3">20<sup>st</sup></td>
              <td className="has-text-right">10:30AM - 11:30AM</td>
              <td>Yoga with Mandy! - All skill levels welcome! Kids who can follow directions.</td>
            </tr>
            <tr className="separator">
              <td rowSpan="2" className="has-text-right">7:00PM</td>
              <td>Black & White Dance Party - Step into a night of style and rhythm at our Black & White Dance Party, where the only rule is to wear black and white—fancy or casual, you decide! Dance the night away under dazzling lights, with a DJ or live music setting the mood for an unforgettable evening. Whether you&apos;re rocking a sleek tux, a little black dress, or comfy black and white casual wear, this night is all about great music, high energy, and effortless fun. Dress to impress—or just to express—and get ready to move! 🖤🤍🎶
              </td>
            </tr>
            <tr>
              <td>DJ by <a href="https://www.facebook.com/profile.php?id=61564314246957">Fireside Beats</a></td>
            </tr>
            <tr className="separator">
              <td>25<sup>th</sup></td>
              <td className="has-text-right">3:00PM - 4:00PM</td>
              <td>Book Club with Michelle - &quot;Jane Eyre&quot; by Charlotte Bronte - Meeting at beach pergolas or in clubhouse
                depending on weather
              </td>
            </tr>
            <tr className="separator">
              <td rowSpan="4">27<sup>th</sup></td>
              <td className="has-text-right">10:30AM - 11:30AM</td>
              <td>Yoga with Mandy! - All skill levels welcome! Kids who can follow directions.</td>
            </tr>
            <tr>
              <td className="has-text-right">10:00AM - 12:00PM</td>
              <td>Rock painting - Rocks provided (or bring your own), paint a rock for our future walkway or take it
                home with you.
              </td>
            </tr>
            <tr>
              <td className="has-text-right">6:30PM - 10:00PM</td>
              <td>Red Rock Oktoberfest - Join us for an unforgettable Oktoberfest celebration featuring The Polka Boyz, steins of beer, traditional Oktoberfest food, kegs, Bienenstich, and festive games! 🍻🎶
              </td>
            </tr>
            <tr>
              <td className="has-text-right">7:00PM - 10:00PM</td>
              <td><a href="https://thepolkaboyz.com/">The Polka Boyz</a> - Live music - Blending traditional polka with popular hits, lively atmosphere that will you dancing and singing along!</td>
            </tr>
            {/*October*/}
            <tr>
              <td colSpan="3" className="has-background-success"><strong className="has-text-white">October</strong>
              </td>
            </tr>
            <tr className="separator">
              <td colSpan="3"><strong>Red Rock Halloween I</strong></td>
            </tr>
            <tr className="separator">
              <td rowSpan="1">4<sup>th</sup></td>
              <td className="has-text-right">10:00AM - 11:00AM</td>
              <td>Crafting - Pumpkin Carving</td>
            </tr>
            <tr className="separator">
              <td colSpan="3"><strong>Red Rock Halloween II / Columbus Day Weekend</strong></td>
            </tr>
            <tr className="separator">
              <td rowSpan="5">11<sup>th</sup></td>
              <td className="has-text-right">3:00PM</td>
              <td>Trick or Treating - Wear your customs and meet at the main building.<br/><strong>NO vehicles past the
                main
                gate after 2:45PM</strong>
              </td>
            </tr>
            <tr>
              <td rowSpan="4" className="has-text-right">6:30PM</td>
              <td>Light Tour - Take a wagon ride and check out the awesome decoration through out the park.</td>
            </tr>
            <tr>
              <td>Haunted Lake Trails - Watch your back, watch your sides for what&apos;s lurking in the woods and in
                the pond!
              </td>
            </tr>
            <tr>
              <td>Hot Cider and Donuts - Get a cup of hot cider and donuts in between the tour.</td>
            </tr>
            <tr>
              <td><strong>Vote for the best decorated site! Prize available for 1st, 2nd and 3rd place.</strong></td>
            </tr>
            <tr>
              <td>12<sup>th</sup></td>
              <td className="has-text-right">7:00PM - 8:00PM</td>
              <td>BINGO - All money returned prizes, win cool Red Rock merchandise!</td>
            </tr>
            </tbody>
            <tfoot>
            <tr>
              <th colSpan="4">Season concludes end of day Oct 19th.</th>
            </tr>
            </tfoot>
          </table>
        </div>
      </section>
    </div>
  );
};

export default ActivityPage;
